import React, { useState, useEffect, useRef } from 'react';
import {
  FaTelegramPlane,
  FaWhatsapp,
  FaKickstarterK,
  FaHeart,
} from 'react-icons/fa';
import { FaXTwitter, FaInstagram, FaYoutube } from 'react-icons/fa6';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import Image from 'next/image';
import Link from 'next/link';
import CustomCarousel from '../testComponents/CustomCarousel';
import useIsMobile from '../../components/testComponents/useIsMobile';
import { useRouter } from 'next/router';
import { fetchStreamers } from '../../helpers/fetchStreamers';
import { fetchRaffleData } from '../../helpers/fetchRaffleData';
import MoreRaffles from '../RafflesComponents/RaffleSlugComponents/MoreRaffles';
import { useSelector } from 'react-redux';
import { RiEmotionUnhappyFill, RiEmotionUnhappyLine } from 'react-icons/ri';

const onButtonHover = {
  scale: 1.01,
  transition: { duration: 0.3 },
  boxShadow: '0 0 20px 0 rgba(53, 144, 248, 0.5)',
};

function HeroLanding() {
  const router = useRouter();
  return (
    <section className='relative mx-auto flex max-w-[1920px] grow flex-col lg:h-[clamp(800px,calc(100vh-75px),1000px)] lg:flex-row lg:gap-4'>
      <main className='flex grow flex-col gap-10 sm:w-full lg:h-full lg:w-3/4'>
        {/* <HeroCarousel /> */}
        <CustomCarousel
          sliders={[
            {
              image: '/casinoItem.webp',
              spanText: `Hoşgeldiniz`,
              title: 'xCerenays ile Yeni Soluk ',
              description:
                'xTeam bünyesinin ilk kadın yayıncısı ile eğlenceye hazır olun!',
              buttonText: 'Takip Et',
              onButtonClick: () => router.push('https://kick.com/xcerenays'),
            },
          ]}
        />
        <div className='-mt-6 lg:hidden'>
          <HeroAside />
        </div>
        <DetailsBox />
      </main>
      <aside className='hidden w-full lg:flex lg:w-1/4 lg:grow-[2]'>
        <HeroAside />
      </aside>
    </section>
  );
}

// #region DETAILS COMPONENT
function DetailsBox() {
  const [streamers, setStreamers] = useState([]);
  const [raffleData, setRaffleData] = useState(null);
  const [allRaffles, setAllRaffles] = useState(null);

  // streamer data
  useEffect(() => {
    async function fetchData() {
      const data = await fetchStreamers();
      setStreamers(data);
      // console.log(data);
    }

    fetchData();
  }, []);

  // raffleData
  useEffect(() => {
    async function fetchRaffle() {
      const raffleData = await fetchRaffleData();
      setRaffleData(raffleData);
    }

    fetchRaffle();
  }, []);

  // fetching all raffles
  useEffect(() => {
    const fetchRaffles = async () => {
      try {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}/raffle/all`,
          {
            params: {
              status: 'all',
              page: 1,
              limit: 10,
            },
          }
        );
        // console.log(response.data.raffles);
        setAllRaffles(response.data.raffles);
      } catch (error) {
        console.error('Error fetching raffles:', error);
      }
    };

    fetchRaffles();
  }, []);

  const { currentMonthYear, totalPrize, totalRaffles, totalMaxWinners } =
    raffleData?.raffleStatistics || {};

  const iconMap = {
    Telegram: <FaTelegramPlane fill='#6B8CB4' />,
    Whatsapp: <FaWhatsapp fill='#6B8CB4' />,
    Kick: <FaKickstarterK fill='#6B8CB4' />,
    Twitter: <FaXTwitter fill='#6B8CB4' />,
    Instagram: <FaInstagram fill='#6B8CB4' />,
    YouTube: <FaYoutube fill='#6B8CB4' />,
  };

  return (
    <div className='relative flex min-h-[500px] w-full flex-col items-center justify-between gap-4'>
      {/* RAFFLES */}
      <div className='relative flex h-full w-full grow flex-col gap-4 text-white lg:h-1/2 lg:w-full'>
        <div className='absolute -top-7 left-0'>
          <span className='text-base font-semibold tracking-wide text-white text-opacity-80'>
            Yeni Çekilişler
          </span>
        </div>

        {/* Raffle Boxes */}
        <div className='flex h-1/2 w-full flex-1 items-center overflow-hidden rounded-lg py-4'>
          {allRaffles && allRaffles.length > 0 ? (
            <MoreRaffles raffles={allRaffles} />
          ) : (
            <p className='text- flex h-full w-full items-center justify-center gap-1 rounded-lg bg-bgDark text-center font-bold'>
              <p>Çekilişler nerede</p>
              <span>
                <RiEmotionUnhappyFill />
              </span>
            </p>
          )}
        </div>
      </div>

      {/* STREAMER */}
      <div className='relative flex h-full w-full grow flex-col gap-4 lg:h-1/2 lg:w-full lg:flex-row'>
        <div className='absolute -top-7 left-0'>
          <span className='text-sm font-semibold tracking-wide text-white text-opacity-80'>
            Yayıncı
          </span>
        </div>

        {streamers.slice(0, 1).map((streamer, index) => (
          <div
            key={index}
            className='relative flex basis-full flex-col items-start justify-around gap-4 overflow-hidden rounded-2xl bg-bgDark p-4'
          >
            {/* glow effect */}
            <div className='pointer-events-none absolute right-0 top-0 z-10 h-1/5 w-1/3 rounded-full bg-accentBlue opacity-40 blur-2xl filter' />
            {/* streamer image & text */}
            <div className='flex gap-4'>
              <div className='img-container relative flex h-16 w-16 shrink-0 rounded-[0.75rem] border-2 border-accentBlue p-1 shadow-lg shadow-accentBlue'>
                <Image
                  className='rounded-lg object-contain object-center'
                  width={400}
                  height={400}
                  src={streamer?.thumbnail}
                  alt={streamer?.streamerName}
                />
              </div>

              <div className='text-container flex flex-col text-white'>
                <span className='tracking-wide text-powderBlue'>
                  Yayıncımız
                </span>
                <h3 className='mb-2 text-2xl lg:text-lg xl:text-2xl'>
                  {streamer?.streamerName}
                </h3>
                <span className='max-w-[210px] text-lg leading-6 text-steelBlue lg:text-base xl:text-lg'>
                  {streamer?.description}
                </span>
              </div>
            </div>

            {/* Buttons */}
            <div className='buttons-container flex h-full w-full shrink flex-wrap items-end justify-start gap-1'>
              {/* Takip/Yayında Button */}
              <motion.button
                // if streamer has a channel link and is streaming, open stream link, else open channel link
                onClick={() =>
                  streamer?.channelLink &&
                  (streamer?.isStreaming
                    ? window.open(
                        streamer.streamLink || streamer.channelLink,
                        '_blank'
                      )
                    : window.open(streamer.channelLink, '_blank'))
                }
                whileHover={{ scale: 1.1 }}
                className='flex h-10 flex-1 items-center justify-center rounded-lg bg-gradient-to-b from-bgDark to-bgDarkest px-4 py-2 text-white'
              >
                {streamer?.isStreaming ? 'Yayında' : 'Takip'}
              </motion.button>

              {/* Social Links Buttons */}
              {streamer?.socialLinks
                ?.sort((a, b) => a.order - b.order)
                .map(({ name, link, _id }) => (
                  <motion.button
                    key={_id}
                    onClick={() => window.open(link, '_blank')}
                    whileHover={{
                      boxShadow: '0 0 10px 0 rgba(53, 144, 248, 0.5)',
                    }}
                    className='backgradientSoft flex h-10 flex-1 items-center justify-center rounded-lg px-4 py-2'
                  >
                    {iconMap[name] || name}
                  </motion.button>
                ))}
            </div>
          </div>
        ))}
        {/* Awards Image */}
        <div className='relative flex h-full min-h-[200px] w-full flex-col items-center justify-center overflow-hidden rounded-lg'>
          <div className='flex flex-col items-center'>
            {/* 1 */}
            <p className='text-center font-bold text-steelBlue'>
              Bu güne kadar çekilişlerle...
            </p>
            {/* glow effect */}
            <div className='absolute bottom-0 left-1/2 h-24 w-1/2 -translate-x-1/2 transform bg-bgDark opacity-50 blur-2xl' />

            {/* total prize */}
            <span className='z-30 flex gap-1 text-center text-4xl font-bold text-white'>
              {totalPrize?.toLocaleString('tr-TR') || 0}
              <span className='textGradientBlue'>&#8378;</span>
            </span>

            {/* 2 */}
            <p className='z-50 text-center font-bold text-steelBlue'>
              dağıttık.
            </p>
          </div>

          {/* Background Images */}
          <div className='absolute -bottom-6 left-0 hidden h-24 w-24 sm:block'>
            <Image src='/coinGold.webp' alt='gold coin' fill />
          </div>
          <div className='absolute right-2 top-2 hidden h-20 w-20 rotate-180 blur-sm sm:block'>
            <Image src='/coinGold.webp' alt='gold coin' fill />
          </div>
          <div className='absolute bottom-4 right-16 hidden h-24 w-24 sm:block'>
            <Image src='/coinSilver.webp' alt='silver coin' fill />
          </div>
          <div className='absolute left-1/4 top-1/4 hidden h-24 w-24 rotate-180 opacity-30 blur-sm sm:block'>
            <Image src='/coinSilver.webp' alt='silver coin' fill />
          </div>
        </div>
      </div>

      {/* AWARDS */}
    </div>
  );
}
// #endregion

//#region ASIDE COMPONENT
function HeroAside({ className }) {
  return (
    <div
      className={`${className ? className : ''} flex h-full w-full flex-col justify-between gap-4`}
    >
      {/* SPONSORS */}
      <GridSponsor className='grid grid-cols-[repeat(auto-fit,minmax(120px,1fr))]' />

      {/* VIP BOX */}
      <VipBox />
    </div>
  );
}
//#endregion

//#region GridSponsor
function GridSponsor() {
  //#region Refs and States
  const containerRef = useRef(null);
  const isDown = useRef(false);
  const start = useRef(0);
  const scrollPosition = useRef(0);
  const velocity = useRef(0);
  const lastPosition = useRef(0);
  const animationFrameId = useRef(null);
  const [feathering, setFeathering] = useState({ top: false, bottom: false });
  const [likedSponsors, setLikedSponsors] = useState([]);
  const isChatOpen = useSelector((state) => state.chat.isOpen);
  console.log(isChatOpen);
  //#endregion

  const isMobile = useIsMobile('1024');
  const isTwoColumn = !useIsMobile('1535') && !isChatOpen;
  const direction = isMobile ? 'horizontal' : 'vertical';

  //#region UseEffect for Feathering & Fetching Sponsors
  useEffect(() => {
    const updateFeathering = () => {
      const container = containerRef.current;
      if (container) {
        const maxScroll =
          direction === 'horizontal'
            ? container.scrollWidth - container.clientWidth
            : container.scrollHeight - container.clientHeight;
        setFeathering({
          top: container.scrollTop > 0,
          bottom: container.scrollTop < maxScroll,
        });
      }
    };

    const container = containerRef.current;
    container.addEventListener('scroll', updateFeathering);
    updateFeathering();
    return () => {
      container.removeEventListener('scroll', updateFeathering);
    };
  }, [direction]);

  useEffect(() => {
    const fetchSponsors = async () => {
      try {
        const response = await axios.get(
          `${process.env.NEXT_PUBLIC_API_URL}/sponsor/mostLikedSponsors`
        );
        // console.log(response.data);
        setLikedSponsors([...response.data]);
      } catch (error) {
        console.error('Sponsorlar getirilirken bir hata oluştu');
      }
    };

    fetchSponsors();
  }, []);

  //  merging two of the copies For development Purposes)
  const allSponsors = [...likedSponsors];
  //#endregion

  //#region Mouse Event Handlers
  const handleMouseDown = (e) => {
    isDown.current = true;
    start.current = direction === 'horizontal' ? e.pageX : e.pageY;
    scrollPosition.current =
      direction === 'horizontal'
        ? containerRef.current.scrollLeft
        : containerRef.current.scrollTop;
    lastPosition.current = direction === 'horizontal' ? e.pageX : e.pageY;
    cancelAnimationFrame(animationFrameId.current);
    document.body.style.userSelect = 'none';
  };

  const handleMouseLeaveOrUp = () => {
    if (!isDown.current) return;
    isDown.current = false;
    startInertiaScroll();
    document.body.style.userSelect = 'auto';
  };

  const handleMouseMove = (e) => {
    if (!isDown.current) return;
    e.preventDefault();
    const currentPosition = direction === 'horizontal' ? e.pageX : e.pageY;
    const walk = (currentPosition - start.current) * 2;
    if (direction === 'horizontal') {
      containerRef.current.scrollLeft = scrollPosition.current - walk;
    } else {
      containerRef.current.scrollTop = scrollPosition.current - walk;
    }
    velocity.current = currentPosition - lastPosition.current;
    lastPosition.current = currentPosition;
  };
  //#endregion

  //#region Touch Event Handlers
  const handleTouchStart = (e) => {
    start.current =
      direction === 'horizontal' ? e.touches[0].pageX : e.touches[0].pageY;
    scrollPosition.current =
      direction === 'horizontal'
        ? containerRef.current.scrollLeft
        : containerRef.current.scrollTop;
    lastPosition.current =
      direction === 'horizontal' ? e.touches[0].pageX : e.touches[0].pageY;
    cancelAnimationFrame(animationFrameId.current);
  };

  const handleTouchEnd = () => {
    startInertiaScroll();
  };

  const handleTouchMove = (e) => {
    if (e.touches.length !== 1) return;
    const currentPosition =
      direction === 'horizontal' ? e.touches[0].pageX : e.touches[0].pageY;
    const walk = (currentPosition - start.current) * 2;
    if (direction === 'horizontal') {
      containerRef.current.scrollLeft = scrollPosition.current - walk;
    } else {
      containerRef.current.scrollTop = scrollPosition.current - walk;
    }
    velocity.current = currentPosition - lastPosition.current;
    lastPosition.current = currentPosition;
  };
  //#endregion

  //#region Inertia Scroll
  const startInertiaScroll = () => {
    const step = () => {
      if (Math.abs(velocity.current) < 0.1) {
        velocity.current = 0;
        return;
      }
      if (direction === 'horizontal') {
        containerRef.current.scrollLeft -= velocity.current;
      } else {
        containerRef.current.scrollTop -= velocity.current;
      }
      velocity.current *= 0.96;
      animationFrameId.current = requestAnimationFrame(step);
    };
    step();
  };
  //#endregion

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        height: 'calc(50% - 20px)',
        justifyContent: 'center',
        // padding: '20px',
        position: 'relative',
        userSelect: 'none',
      }}
    >
      <div
        className={`hide-scroll flex ${isMobile ? 'h-full' : ''} ${direction === 'horizontal' ? 'flex-nowrap' : 'flex-wrap'}`}
        style={{
          maxHeight: direction === 'horizontal' ? 'auto' : '600px',
          maxWidth: direction === 'horizontal' ? '100%' : 'auto',
          overflowY: direction === 'horizontal' ? 'hidden' : 'auto',
          overflowX: direction === 'horizontal' ? 'auto' : 'hidden',
          width: '100%',
        }}
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeaveOrUp}
        onMouseUp={handleMouseLeaveOrUp}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        {/* if there is no data */}
        {allSponsors.length === 0 && (
          <div className='hidden h-full w-full items-center justify-center lg:flex'>
            <p className='flex h-full w-full items-center justify-center gap-2 rounded-lg bg-bgDark font-bold text-white'>
              Burası da boş kalmış <RiEmotionUnhappyFill />
            </p>
          </div>
        )}
        <style>
          {`
            .hide-scroll::-webkit-scrollbar {
              display: none;
            }
            .hide-scroll {
              -ms-overflow-style: none;  /* IE and Edge */
              scrollbar-width: none;  /* Firefox */
            }
            .non-draggable {
              user-select: none;
              -webkit-user-drag: none;
            }
          `}
        </style>
        {allSponsors.map((sponsor, index) => (
          <motion.a
            target='_blank'
            href={sponsor.link}
            key={`sponsor-${index}}`}
            className={`non-draggable flex ${isMobile ? 'h-[40%]' : ''} items-center justify-center overflow-hidden rounded-lg`}
            style={{
              // height: '200px',
              // TODO: One Line it
              flexBasis:
                direction === 'horizontal'
                  ? '200px'
                  : isTwoColumn
                    ? 'calc(50% - 16px)'
                    : '50%',
              flexGrow: direction === 'horizontal' ? 0 : 1,
              flexShrink: 0,
              margin: direction === 'horizontal' ? '0 8px' : '8px',
              pointerEvents: 'auto',
            }}
            draggable='false'
          >
            <div
              className='non-draggable group flex h-full w-full items-center justify-center bg-blue-200'
              draggable='false'
            >
              {/* Item */}
              <div className='flex h-full w-full flex-col items-center justify-center gap-4 bg-bgDark'>
                <img
                  draggable='false'
                  src={`https://d38ezbe56qv4qs.cloudfront.net/img/sponsors/${sponsor.name.toLowerCase()}.webp`}
                  alt={sponsor.name}
                  fetchpriority='high'
                  className='h-[50%] w-[auto] max-w-[calc(100%-20px)] object-contain duration-200 group-hover:scale-110'
                />
                <div className='flex items-center justify-center gap-2'>
                  <FaHeart size={14} fill='#FF4093' />
                  <span className='text-white'>{sponsor.likes}</span>
                </div>
              </div>
            </div>
          </motion.a>
        ))}
      </div>
      {!isMobile && (
        <Feathering direction={feathering.top ? 'top' : 'bottom'} />
      )}
    </div>
  );
}
//#endregion

// #region VipBox
function VipBox() {
  const router = useRouter();
  return (
    <div className='flex w-full flex-col justify-center gap-2 rounded-lg bg-gradient-to-b from-[#4d1447] to-[#40113b] p-4 text-white md:h-[50%] 2xl:gap-4'>
      {/* image */}
      <div className='max-h-1/2 relative min-h-[150px] w-full flex-[0_1_50%] overflow-hidden lg:min-h-0'>
        <Image
          className='h-full max-h-[200px] w-full object-contain object-center lg:max-h-max'
          fill
          src='/vipHowto.png'
          // src='https://d38ezbe56qv4qs.cloudfront.net/img/test/Anasayfa/VipLanding/vip-badges.png'
          alt='vip badges'
        />
      </div>

      {/* text & button */}
      <div className='flex h-auto flex-col gap-2'>
        <h3 className='text-2xl font-bold'>Bizi takip et</h3>
        <p className='opacity-80'>
          WhatsApp topluluğumuzun bir parçası ol ve X ailesi ile kazanmaya
          başla!
        </p>
        {/* centers the button between bottom & p 👇🏻 */}
        <div className='flex grow items-center justify-center'>
          <motion.a
            whileHover={onButtonHover}
            className='buttonGradientBlue mb-2 w-full rounded-lg px-8 py-4 text-center'
            href='https://xkisa.de/wp'
            target='_blank'
            rel='noopener noreferrer'
          >
            Topluluğa Katıl
          </motion.a>
        </div>
      </div>
    </div>
  );
}
// #endregion

// #region Feathering
function Feathering({ direction }) {
  //#region Feathering Styles
  const topStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '50px',
    background: 'linear-gradient(to bottom, #20081e, #20081e00)',
    pointerEvents: 'none',
  };

  const bottomStyle = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '50px',
    background: 'linear-gradient(to top, #20081e, #20081e00)',
    pointerEvents: 'none',
  };
  //#endregion

  return (
    <>
      {direction === 'top' && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={topStyle}
          />
        </AnimatePresence>
      )}
      {direction === 'bottom' && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={bottomStyle}
          />
        </AnimatePresence>
      )}
    </>
  );
}
// #endregion

export default HeroLanding;
