import axios from 'axios';

export async function fetchRaffleData() {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/raffle/statistics`
    );
    return {
      monthlyRaffles: response.data.monthlyRaffles,
      raffleStatistics: {
        totalPrize: response.data.totalPrize,
        totalRaffles: response.data.totalRaffles,
        totalMaxWinners: response.data.totalMaxWinners,
        currentMonthYear: response.data.currentMonthYear,
      },
    };
  } catch (error) {
    console.error('Failed to fetch raffle statistics:', error);
    return {
      monthlyRaffles: [],
      raffleStatistics: {
        totalPrize: 0,
        totalRaffles: 0,
        totalMaxWinners: 0,
        currentMonthYear: '',
      },
    };
  }
}
