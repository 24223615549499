import React, { useRef, useState, useEffect, useCallback } from 'react';

const CustomSlider = ({ children }) => {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [velocity, setVelocity] = useState(0);
  const [lastMouseX, setLastMouseX] = useState(0);
  const [lastMoveTime, setLastMoveTime] = useState(Date.now());
  const [showFeatherLeft, setShowFeatherLeft] = useState(false);
  const [showFeatherRight, setShowFeatherRight] = useState(true);
  const [dragged, setDragged] = useState(false);
  const [isInteracted, setIsInteracted] = useState(false);

  const handleTouchStart = (e) => {
    setIsDragging(true);
    const touch = e.touches[0];
    setStartX(touch.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
    setVelocity(0);
    setLastMouseX(touch.pageX);
    setLastMoveTime(Date.now());
    setDragged(false);
    setIsInteracted(true); // Update isInteracted on touch start
  };

  const handleTouchMove = useCallback(
    (e) => {
      if (!isDragging) return;
      e.preventDefault(); // preventing default scrolling
      const touch = e.touches[0];
      const x = touch.pageX - scrollContainerRef.current.offsetLeft;
      const walk = x - startX;
      scrollContainerRef.current.scrollLeft = scrollLeft - walk;

      const now = Date.now();
      const deltaX = touch.pageX - lastMouseX;
      const deltaTime = now - lastMoveTime;
      setVelocity(deltaX / deltaTime);
      setLastMouseX(touch.pageX);
      setLastMoveTime(now);

      handleScroll();
      if (Math.abs(walk) > 5) setDragged(true);
    },
    [isDragging, startX, scrollLeft, lastMouseX, lastMoveTime]
  );

  const handleTouchEnd = (e) => {
    setIsDragging(false);
    if (dragged) {
      applyMomentum();
      e.preventDefault();
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
    setVelocity(0);
    setLastMouseX(e.pageX);
    setLastMoveTime(Date.now());
    setDragged(false);
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - scrollContainerRef.current.offsetLeft;
      const walk = x - startX;
      scrollContainerRef.current.scrollLeft = scrollLeft - walk;

      const now = Date.now();
      const deltaX = e.pageX - lastMouseX;
      const deltaTime = now - lastMoveTime;
      setVelocity(deltaX / deltaTime);
      setLastMouseX(e.pageX);
      setLastMoveTime(now);

      handleScroll();
      if (Math.abs(walk) > 5) setDragged(true);
    },
    [isDragging, startX, scrollLeft, lastMouseX, lastMoveTime]
  );

  const handleMouseUp = (e) => {
    setIsDragging(false);
    if (dragged) {
      applyMomentum();
      e.preventDefault();
    }
  };

  const handleClick = (e) => {
    if (dragged) {
      e.preventDefault();
    }
  };

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    setShowFeatherLeft(scrollLeft > 0);
    setShowFeatherRight(scrollLeft + clientWidth < scrollWidth);
  };

  const applyMomentum = () => {
    let currentVelocity = velocity;

    const momentumScroll = () => {
      if (Math.abs(currentVelocity) < 0.1) return;
      scrollContainerRef.current.scrollLeft -= currentVelocity * 20;
      currentVelocity *= 0.95;
      handleScroll();
      requestAnimationFrame(momentumScroll);
    };

    momentumScroll();
  };

  useEffect(() => {
    handleScroll();
    const container = scrollContainerRef?.current;

    if (container) {
      // mouse event listeners
      container.addEventListener('mousedown', handleMouseDown);
      container.addEventListener('mousemove', handleMouseMove);
      container.addEventListener('mouseup', handleMouseUp);

      // touch event listeners as non-passive
      container.addEventListener('touchstart', handleTouchStart, {
        passive: false,
      });
      container.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
      container.addEventListener('touchend', handleTouchEnd);

      // scroll event listener
      container.addEventListener('scroll', handleScroll);

      // cleanup
      return () => {
        container.removeEventListener('mousedown', handleMouseDown);
        container.removeEventListener('mousemove', handleMouseMove);
        container.removeEventListener('mouseup', handleMouseUp);
        container.removeEventListener('touchstart', handleTouchStart);
        container.removeEventListener('touchmove', handleTouchMove);
        container.removeEventListener('touchend', handleTouchEnd);
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleMouseMove, handleTouchMove]);

  return (
    <div
      onMouseLeave={() => setIsDragging(false)}
      className='relative overflow-hidden'
    >
      <div
        ref={scrollContainerRef}
        className='feather-effect no-scroll flex touch-auto select-none flex-nowrap gap-4 overflow-x-scroll pb-4'
        onClick={handleClick}
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            onMouseDown: handleClick,
            onClick: handleClick,
          })
        )}
      </div>
      {showFeatherLeft && <div className='feather-left'></div>}
      {showFeatherRight && <div className='feather-right'></div>}
      {/* fake initial feather */}
      {!isInteracted && <div className='feather-right'></div>}
      <style jsx>{`
        .feather-left,
        .feather-right {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 50px;
          pointer-events: none;
        }
        .feather-left {
          left: 0;
          background: linear-gradient(
            to right,
            #7f43d7,
            rgba(255, 255, 255, 0)
          );
        }
        .feather-right {
          right: 0;
          background: linear-gradient(to left, #20081e, #20081e00);
        }
      `}</style>
    </div>
  );
};

export default CustomSlider;
