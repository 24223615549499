import axios from 'axios';

export async function fetchStreamers() {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/misc/streamers-status`
    );
    return response.data.data;
  } catch (error) {
    console.error('Failed to fetch streamers:', error);
    return [];
  }
}
