import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';

function CustomCarousel({ sliders, interval = 2000 }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const slideInterval = useRef(null);
  const startX = useRef(0);
  const endX = useRef(0);
  const isDragging = useRef(false);

  // autoplay events
  useEffect(() => {
    if (!isHovered) startAutoPlay();
    return () => stopAutoPlay();
  }, [isHovered]);

  const startAutoPlay = () => {
    slideInterval.current = setInterval(() => {
      setCurrentSlide((prev) => (prev === sliders.length - 1 ? 0 : prev + 1));
    }, interval);
  };

  const stopAutoPlay = () => clearInterval(slideInterval.current);

  // Touch Event Handlers
  const handleTouchStart = (e) => {
    stopAutoPlay();
    startX.current = e.touches[0].clientX;
    isDragging.current = true;
  };

  const handleTouchMove = (e) => {
    if (isDragging.current) endX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (isDragging.current) {
      if (startX.current - endX.current > 50)
        setCurrentSlide((prev) => (prev === sliders.length - 1 ? 0 : prev + 1));

      if (startX.current - endX.current < -50)
        setCurrentSlide((prev) => (prev === 0 ? sliders.length - 1 : prev - 1));

      if (!isHovered) startAutoPlay();
      isDragging.current = false;
    }
  };

  // Mouse Event Handlers
  const handleMouseDown = (e) => {
    stopAutoPlay();
    startX.current = e.clientX;
    isDragging.current = true;
  };

  const handleMouseMove = (e) => {
    if (isDragging.current) endX.current = e.clientX;
  };

  const handleMouseUp = () => {
    if (isDragging.current) {
      if (startX.current - endX.current > 50)
        setCurrentSlide((prev) => (prev === sliders.length - 1 ? 0 : prev + 1));

      if (startX.current - endX.current < -50)
        setCurrentSlide((prev) => (prev === 0 ? sliders.length - 1 : prev - 1));

      if (!isHovered) startAutoPlay();
      isDragging.current = false;
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    stopAutoPlay();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    startAutoPlay();
  };

  return (
    <div
      className='relative mx-auto w-full select-none overflow-hidden rounded-lg bg-gradient-to-t from-bgDark to-bgDarkest md:h-full'
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={handleMouseEnter}
    >
      <div className='flex h-full w-full'>
        {sliders.map((slider, i) => (
          <div
            className='relative h-full w-full shrink-0 basis-full transition-transform duration-500'
            key={i}
            style={{
              transform: `translateX(-${currentSlide * 100}%)`,
            }}
          >
            <div className='absolute -bottom-0 right-0 z-10 w-1/2'>
              {/* glow effect */}
              <div className='pointer-events-none absolute -bottom-8 -right-4 z-20 h-40 w-full rounded-full bg-nav opacity-60 blur-2xl filter' />
              {/* overlay */}
              <Image
                width={400}
                height={400}
                src={slider.image}
                alt={`slide-${i}`}
                className='absolute -bottom-8 -right-8 z-10 object-contain grayscale hue-rotate-90'
                draggable={false}
              />
              {/* backgraound */}
              <Image
                width={400}
                height={400}
                src={slider.image}
                alt={`slide-${i}`}
                className='absolute -bottom-8 -right-8 object-contain'
                draggable={false}
              />
            </div>
            <div className='z-50 flex h-full w-full flex-col items-start justify-center gap-3 px-4 py-8 md:p-8'>
              <span
                className={
                  slider.spanClass ||
                  'w-fit rounded-full bg-bgTag px-4 py-2 text-center text-sm text-white text-opacity-80 lg:px-2 lg:py-1 lg:text-sm xl:px-4 xl:py-2 xl:text-lg'
                }
              >
                {slider.spanText}
              </span>
              <h1
                className={
                  slider.titleClass ||
                  'text-balance z-50 font-sans text-xl font-bold tracking-tight text-white md:max-w-[60%] md:text-3xl lg:text-2xl xl:text-4xl xl:leading-tight'
                }
              >
                {slider.title}
              </h1>
              <p
                className={
                  slider.descriptionClass ||
                  'z-50 mb-3 max-w-[50%] font-sans text-sm text-white text-opacity-80 lg:text-base xl:text-lg'
                }
              >
                {slider.description}
              </p>

              {slider.buttonText && (
                <button
                  onClick={slider.onButtonClick}
                  className={
                    slider.buttonClass ||
                    'buttonGradientBlue rounded-lg px-4 py-2 text-white hover:scale-105 lg:px-2 lg:py-1 xl:px-4 xl:py-2 2xl:px-8 2xl:py-4'
                  }
                >
                  {slider.buttonText}
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className='absolute bottom-4 left-1/2 flex -translate-x-1/2 transform cursor-pointer space-x-2'>
        {sliders.map((_, i) => (
          <div
            key={i}
            className={`h-2 w-2 rounded-full ${i === currentSlide ? 'bg-white' : 'bg-gray-500'}`}
            onClick={() => {
              setCurrentSlide(i);
              stopAutoPlay();
              if (!isHovered) {
                startAutoPlay();
              }
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default CustomCarousel;
