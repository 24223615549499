import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import HeroLanding from '/components/MainPageComponents/HeroLanding';
import { useRouter } from 'next/router';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { ImSpinner2 } from 'react-icons/im';

const Index = () => {
  const router = useRouter();
  const userID = useSelector((state) => state.user.userUID);

  const [showModal, setShowModal] = useState(false);
  const [linkFrom, setLinkFrom] = useState('');
  const [receivedID, setReceivedID] = useState('');
  const [basePath, setBasePath] = useState('');
  const [modalText, setModalText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    if (router.isReady) {
      // Get the base URL from window.location
      let baseUrl = '';
      if (typeof window !== 'undefined') {
        baseUrl = window.location.origin;
        setBasePath(baseUrl);
      }

      if (router.query.linkfrom && router.query.userID) {
        // Store the query parameters in state
        setLinkFrom(router.query.linkfrom);
        setReceivedID(router.query.userID);
        // Show the modal to confirm linking
        setShowModal(true);
        if (userID === !receivedID) {
          setModalText("Hesap bağlantısı yapılamadı. ID'niz aynı olamaz.");
        } else {
          setModalText('Hesabınızı bağlamak istiyor musunuz?');
        }
      }
    }
  }, [
    router.isReady,
    router.query.linkfrom,
    router.query.userID,
    receivedID,
    userID,
  ]);

  const sanitizeAndClearFromURL = (url) => {
    if (url.includes('localhost')) {
      return 'localhost';
    } else if (url.includes('xcerenays')) {
      return 'xCerenays';
    } else if (url.includes('xahmetdosa')) {
      return 'xAhmetDosa';
    } else if (url.includes('xjackpots')) {
      return 'xJackpots';
    } else {
      return 'unknown';
    }
  };

  const handleConfirm = () => {
    setIsLoading(true);
    axios
      .post(
        `${process.env.NEXT_PUBLIC_API_URL}/auth/connect-website`,
        {
          siteToLinkFrom: linkFrom,
          receivedID,
          userID,
          siteToLinkTo: basePath,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          setResponseMessage('Hesap başarıyla bağlandı.');
        } else {
          setResponseMessage(response.data.msg);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setResponseMessage(error.response.data.msg);
      });
  };

  const handleCancel = () => {
    // Close the modal without linking accounts
    setShowModal(false);
    // Optionally, redirect or perform other actions
  };

  return (
    <div className='w-full overflow-hidden'>
      <Head>
        <title>xCerenays 💵 Anasayfa</title>
      </Head>
      <HeroLanding />
      {showModal && (
        // Modal overlay
        <div
          className='fixed inset-0 z-[10000] flex items-center justify-center bg-black bg-opacity-50'
          onClick={handleCancel}
        >
          {/* Modal content */}
          <div
            className='mx-4 w-full max-w-md rounded-lg bg-nav p-6 text-text shadow-lg'
            onClick={(e) => e.stopPropagation()}
          >
            {isLoading ? (
              <div className='flex flex-col items-center'>
                <ImSpinner2 className='animate-spin text-4xl text-blue-600' />
                <p className='mt-4'>Bağlanıyor...</p>
              </div>
            ) : responseMessage ? (
              <div className='flex flex-col items-center'>
                {responseMessage === 'Hesap başarıyla bağlandı.' ? (
                  <FaCheckCircle className='text-4xl text-green-500' />
                ) : (
                  <FaTimesCircle className='text-4xl text-red-500' />
                )}
                <p className='mt-4'>{responseMessage}</p>
                <button
                  onClick={() => {
                    setShowModal(false);
                    window.location.href = '/';
                  }}
                  className='buttonGradientBlue mt-4 rounded px-4 py-2 text-white hover:bg-blue-700'
                >
                  Kapat
                </button>
              </div>
            ) : (
              <div>
                <h2 className='mb-4 text-2xl font-semibold'>
                  Hesap Bağlama Onayı
                </h2>
                <p className='mb-6'>
                  {modalText.startsWith('Hesap bağlantısı yapılamadı') ? (
                    modalText
                  ) : (
                    <>
                      <span className='font-bold'>
                        {sanitizeAndClearFromURL(basePath)}
                      </span>{' '}
                      hesabınızı <span className='font-bold'>{linkFrom}</span>{' '}
                      ile bağlamak istiyor musunuz?
                    </>
                  )}
                </p>
                <div className='flex justify-end space-x-4'>
                  <button
                    onClick={handleCancel}
                    className='rounded bg-gray-200 px-6 py-2 text-gray-800 hover:bg-gray-300'
                  >
                    {modalText.startsWith('Hesap bağlantısı yapılamadı')
                      ? 'İptal'
                      : 'Hayır'}
                  </button>
                  {modalText.startsWith('Hesap bağlantısı yapılamadı') ? (
                    <></>
                  ) : (
                    <button
                      onClick={handleConfirm}
                      className='buttonGradientBlue w-24 rounded px-4 py-2 text-white hover:bg-blue-700'
                      disabled={modalText.startsWith(
                        'Hesap bağlantısı yapılamadı'
                      )}
                    >
                      Evet
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
