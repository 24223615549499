import React from 'react';
import RaffleBox from '../RaffleBox';
import CustomSlider from '/components/testComponents/CustomSlider';

const MoreRaffles = ({ raffles }) => {
  return (
    <div className='w-full'>
      <CustomSlider>
        {raffles?.map((raffle) => (
          <RaffleBox raffle={raffle} key={raffle.order} />
        ))}
      </CustomSlider>
    </div>
  );
};

export default MoreRaffles;
